/********** Template CSS **********/

:root {
  --primary: #ea8b0f;
  --primary2: #c4710e;

  --light: #f1f8ff;
  --dark: #0f172b;
}

.disabled-a {
  pointer-events: none;
  cursor: default;
}

.table-head {
  background-color: rgba(255, 166, 0, 0.3) !important;
}

.accordion-title:hover {
  background-color: rgba(255, 166, 0, 0.3) !important;
}
.accordion-back {
  background-color: rgba(255, 166, 0, 0.3) !important;
}
.log-row:hover {
  background-color: rgba(255, 166, 0, 0.2) !important;
}

.avatar-style {
  width: 39px !important;
  height: 39px !important;
  background-color: var(--primary) !important;
}

.avatar-style1 {
  width: 39px !important;
  height: 39px !important;
}
.list-item:hover {
  color: #c4710e !important;
  background-color: rgba(255, 166, 0, 0.1) !important;
}

.detalhes-menu {
  border-radius: 7px !important;
  width: 100px !important;
}

.detalhes-menu1 {
  border-radius: 7px !important;
  width: 120px !important;
}

.div-detalhes {
  max-height: 320px !important;
  overflow: auto !important;
}

.actions-button:hover {
  background-color: rgba(128, 128, 128, 0.1) !important;
}

.link-dashboard:hover {
  color: var(--primary) !important;
}

.accordion-buttons:hover {
  background-color: rgba(128, 128, 128, 0.15) !important;
}

.button-users:hover {
  box-shadow: 1px 4px 4px gray !important;
}

.close-modal:active {
  color: white !important;
  background-color: #ea8b0f !important;
}
.close-modal:hover {
  color: white !important;
  background-color: #ea8b0f !important;
}

.divider {
  width: 100%;
  margin: 20px 0px 0px 0px;
  height: 1px;
  background-color: rgba(234, 139, 15, 0.3);
}
.appbar-admin {
  background-color: var(--dark) !important;
}

.link-login:hover {
  color: var(--primary) !important;
}
.bt-dark {
  background-color: #c4710e !important;
}
.bt-card {
  font-size: small !important;
}
.bt-card:hover {
  color: #c4710e !important;
}

.ver-mais:hover {
  background-color: rgba(128, 128, 128, 0.09);
}

.card-servicos:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.card-servicos {
  height: 370px !important;
}

.texto-servicos {
  height: 110px;
}

.ff-secondary {
  font-family: "Pacifico", cursive;
}

.fw-medium {
  font-weight: 600 !important;
}

.fw-semi-bold {
  font-weight: 700 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 10px;
  bottom: 35px;
  z-index: 99;
}

.back-to-top2 {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
  margin-top: 9px;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-lg-square:hover {
  color: var(--primary) !important;
  background-color: var(--light) !important;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 2px;
}

/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
  position: relative;
  margin-left: 25px;
  padding: 35px 0;
  font-size: 15px;
  color: var(--light) !important;
  text-transform: uppercase;
  font-weight: 500;
  outline: none;
  transition: 0.5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
  padding: 20px 0;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: var(--primary) !important;
}

.navbar-dark .navbar-brand img {
  max-height: 60px;
  transition: 0.5s;
}

.sticky-top.navbar-dark .navbar-brand img {
  max-height: 45px;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-dark {
    position: relative;
  }
  .navbar-dark {
    background-color: var(--dark) !important;
  }

  .navbar-dark .navbar-collapse {
    margin-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .navbar-dark .navbar-nav .nav-link,
  .sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 10px 0;
    margin-left: 0;
  }

  .navbar-dark .navbar-brand img {
    max-height: 45px;
  }

  .paper,
  .tabCasos {
    display: none;
  }

  .search-div {
    margin-top: -30px !important;
    margin-bottom: 20px !important;
  }
  .button-details {
    width: 140px !important;
  }
}

@media (min-width: 992px) {
  .navbar-dark {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: transparent !important;
  }

  .sticky-top.navbar-dark {
    position: fixed;
    background: var(--dark) !important;
  }
  .paper1,
  .tabCasos1 {
    display: none;
  }
}

@media (min-width: 768px) {
  .button-details {
    width: 150px !important;
  }
}

@media (max-width: 768px) {
  .tab-casos {
    width: 50% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .paper1 {
    display: none !important;
  }
  .paper {
    display: block !important;
  }
  .search-input {
    max-width: 200px !important;
  }
}

@media (min-width: 991px) and (max-width: 1060px) {
  .academia-logo {
    width: 210px !important;
  }
}

.paper-users {
  height: 350px !important;
}

.paper,
.paper1 {
  box-shadow: 4px 10px 4px var(--primary) !important;
}
/*** Hero Header ***/
.hero-header {
  background: linear-gradient(rgba(15, 23, 43, 0.9), rgba(15, 23, 43, 0.9)),
    url(../img/bg-hero.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
}
.hero-header-denuncias {
  background: linear-gradient(rgba(15, 23, 43, 0.9), rgba(15, 23, 43, 0.9)),
    url(../img/violencia2.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
}
.hero-header-permissao {
  background: linear-gradient(rgba(15, 23, 43, 0.9), rgba(15, 23, 43, 0.9)),
    url(../img/bg-hero.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
  min-height: 100vh !important;
  height: auto !important;
}
.hero-header-login {
  background: linear-gradient(rgba(15, 23, 43, 0.9), rgba(15, 23, 43, 0.9)),
    url(../img/bg-hero.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
}

.hero-header-detalhes {
  margin-top: -100px;
  height: 250px;
}

.hero-header-sobre {
  margin-top: -100px;
}

.hero-header-estudos {
  background: linear-gradient(rgba(15, 23, 43, 0.9), rgba(15, 23, 43, 0.9)),
    url(../img/estudante.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
}

.hero-header-ideias {
  background: linear-gradient(rgba(15, 23, 43, 0.9), rgba(15, 23, 43, 0.9)),
    url(../img/ideia1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
}
.hero-header-saude {
  background: linear-gradient(rgba(15, 23, 43, 0.9), rgba(15, 23, 43, 0.9)),
    url(../img/saude1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
}

.hero-header img {
  animation: imgRotate 50s linear infinite;
}

@keyframes imgRotate {
  100% {
    transform: rotate(360deg);
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.5);
}

/*** Section Title ***/
.section-title {
  position: relative;
  display: inline-block;
}

.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  left: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.section-title::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  right: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.section-title.text-start::before,
.section-title.text-end::after {
  display: none;
}

/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}

.service-item * {
  transition: 0.5s;
}

/*** Food Menu ***/
.nav-pills .nav-item .active {
  border-bottom: 2px solid var(--primary);
}

.tab-casos:hover {
  background-color: rgba(128, 128, 128, 0.09);
}

.images {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.images1 {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
/*** Youtube Video ***/
.video {
  position: relative;
  height: 100%;
  min-height: 500px;
  background: linear-gradient(rgba(15, 23, 43, 0.1), rgba(15, 23, 43, 0.1)),
    url(../img/video.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video .btn-play {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

.video .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

#videoModal {
  z-index: 99999;
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #ffffff;
  background: #000000;
  opacity: 1;
}

/*** Team ***/
.team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  height: calc(100% - 38px);
  transition: 0.5s;
}

.team-item img {
  transition: 0.5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

.team-item:hover {
  height: 100%;
}

.team-item .btn {
  border-radius: 38px 38px 0 0;
}

/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
  transition: 0.5s;
}

.testimonial-item:hover {
  background-color: rgba(128, 128, 128, 0.09) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item * {
  color: var(--light) !important;
}

.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #cccccc;
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--primary);
  border-color: var(--primary);
}

.detalhes:hover {
  text-decoration: underline;
}

.detalhes2 {
  text-decoration: underline;
  cursor: pointer;
}
.detalhes2:hover {
  color: var(--primary) !important;
}

.listagem {
  background-color: rgba(254, 161, 22, 0.07);
}

.btn-search:hover {
  background-color: var(--light) !important;
  color: var(--primary) !important;
}

.btn-search:disabled {
  background-color: var(--light) !important;
  color: var(--primary) !important;
}

.back-notfound {
  text-decoration: underline;
  cursor: pointer;
}

.back-notfound:hover {
  color: #3f51b5 !important;
}

/*** Footer ***/

.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid #ffffff;
  border-radius: 35px;
  transition: 0.3s;
}

.mail-to:hover {
  color: #ea8b0f !important;
}

.footerMenu:hover {
  color: var(--primary) !important;
  cursor: pointer !important;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
body {
  background-color: rgba(15, 23, 43, 0.005) !important;
}
@media only screen and (max-width: 600px) {
  .map {
    width: 60% !important;
    /* margin-top: 90px !important; */
  }
}

/* ----------------SIDEBAR CSS-------------------------- */

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}

.label-details {
  font-size: small;
  color: #3f51b5;
  font: bold;
}

.links {
  color: #3f51b5 !important;
}
.links:hover {
  text-decoration: underline !important;
}
/* ----- v CAN BE DELETED v ----- */
body {
  background-color: #78909c;
}

.demo {
  padding-top: 60px;
  padding-bottom: 110px;
}

.btn-demo {
  margin: 15px;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 16px;
  background-color: #ffffff;
}

.btn-demo:focus {
  outline: 0;
}

.footer-container {
  margin-bottom: -100px !important;
}

.demo-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: #921616;
  text-align: center;
}

.demo-footer > a {
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

.centerr {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* ------------------CAROUSEL HOME-------------------------------- */

.imaged {
  height: auto;
  border-radius: 10px;
}

.imaged.w16 {
  width: 16px !important;
}

.imaged.w24 {
  width: 24px !important;
}

.imaged.w32 {
  width: 32px !important;
}

.imaged.w36 {
  width: 36px !important;
}

.imaged.w48 {
  width: 48px !important;
}

.imaged.w64 {
  width: 64px !important;
}

.imaged.w76 {
  width: 76px !important;
}

.imaged.w86 {
  width: 86px !important;
}

.imaged.w100 {
  width: 100px !important;
}

.imaged.w120 {
  width: 120px !important;
}

.imaged.w140 {
  width: 140px !important;
}

.imaged.w160 {
  width: 160px !important;
}

.imaged.w180 {
  width: 180px !important;
}

.imaged.w200 {
  width: 200px !important;
}

.imaged.img-fluid {
  width: 100%;
}

.imaged.rounded {
  border-radius: 100% !important;
}

.imaged.radius {
  border-radius: 10px !important;
}

.imaged.square {
  border-radius: 0 !important;
}

.carousel-single .splide__slide {
  margin-left: -16px !important;
  padding-right: 16px !important;
}

.carousel-multiple .splide__slide {
  margin-left: -8px !important;
  padding-right: 8px !important;
}

.carousel-small .splide__slide {
  margin-left: -8px !important;
  padding-right: 0px !important;
}

.carousel-small .splide__slide:first-child {
  margin-left: 24px !important;
}

.blog-card {
  padding: 0;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
}

.blog-card img {
  border-radius: 10px 10px 0 0;
  height: 100px;
}

.blog-card .text {
  padding: 10px 10px;
}

.blog-card .title {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4em;
  height: 67px;
  overflow: hidden;
}

.blog-card small {
  font-size: 11px;
  color: #a9abad;
  margin: 0;
  line-height: 1.2em;
}

.profile-card {
  background-color: rgba(255, 166, 0, 0.05) !important;
}

/* ----STYLE TO INPUT FILE-------*/
.upload-photo {
  display: none !important;
}
.label1 {
  background-color: rgb(255, 255, 255);
  color: var(--dark);
  padding: 0.2rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  border-width: 1px;
  border-color: var(--primary);
  font-style: inherit !important;
  border-style: groove;
  cursor: pointer;
  margin-top: 1rem;
  height: 40px !important;
}

.label1:hover {
  color: var(--primary);
}

#file-chosen {
  margin-left: 0.3rem;
  font-family: sans-serif;
}

.blur-name {
  color: transparent;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.blur-name-home {
  color: transparent;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.blur-name-home1 {
  color: transparent;
  text-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.history-desc1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* .child {
  display: none;
}

.parent:hover .child {
  display: block;
} */
